// @flow
import * as React from 'react';
import {useContext, useMemo} from "react";
import Input from "../fieldByType/fieldByType";

type Props = {

};

const RenderField = (props: Props) => {
    const {
        formik,
        field,
        values,
        handleBlur,
        handleChange,
        touched,
        errors
    } = props

    const checkIfFieldVisible = useMemo(() => {
        if (!field.enabled) return
        if (!field.visibleOnPreRegistrationForm) return
        if (field.type === 'layout_element') return
        return true
    }, [field.enabled, field.type, field.visibleOnPreRegistrationForm])

    const value = useMemo(() => (
        values &&
        values[field.id]
    ) ? values[field.id] :
        field.fieldType === "select" && field.multiple ? []
            : '', [field.id, field.multiple, field.type, values])
    if (!checkIfFieldVisible) return null
    return (
        <Input
            formik={formik}
            values={values}
            field={field}
            value={value}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
        />
    )
};

export default RenderField