//@flow
import * as React from 'react';
import {Divider} from "@mui/material";
import type {Exhibition} from "../../types/exhibition/types";
import {useMultilingualText} from "../../hooks/useMultilingualText";
import Typography from "@mui/material/Typography";
import {formatDate} from "../../helpers/date";

type Props = {
    exhibition: Exhibition,
    noImage?: boolean
}

const Top = (props: Props) => {
    const {exhibition, noImage} = props
    const {title: anyLanguageTitle, image, dateFrom, dateTo} = exhibition
    const imageInLanguage = useMultilingualText(image)
    const title = useMultilingualText(anyLanguageTitle)
    const content = (
        <React.Fragment>
            <Typography sx={{mb: 1, fontSize: noImage ? 12 : null}} color="text.secondary" variant="subtitle1">
                {title}
            </Typography>
            <Typography variant="h5">
                <strong>{formatDate(dateFrom, formatDate.formatTypes.DAY)}</strong>
                <strong>-</strong>
                <strong>{formatDate(dateTo, formatDate.formatTypes.DAY)}/{formatDate(dateTo, formatDate.formatTypes.MONTH_DOUBLE)}</strong>
                <strong>/{formatDate(dateTo, formatDate.formatTypes.YEAR)}</strong>
            </Typography>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            {!image || noImage || window.location !== window.parent.location ? content : (
                <img
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                        minHeight: '200px',
                        maxHeight: "240px",
                        objectFit: "contain"
                    }}
                    src={imageInLanguage}
                    alt={'exhibition logo'}
                />
            )}
            <Divider/>
        </React.Fragment>
    )
}

export default Top;