// @flow
import React, {useContext} from 'react';
import {findCountryCode} from "../../../helpers/helpers";
import CountryPhoneCodeSelect from "./countryPhoneCodeSelect";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import {PreRegistrationContext} from "../../form/form";
import {textfieldProps} from "./text";
import Box from "@mui/material/Box";

type Props = {};

const MobilePhone = (props: Props) => {
    const {formik, values, field, value, handleBlur, handleChange, touched, errors, disabled} = props
    const {t} = useTranslation(VISITOR_PROFILE)
    const {
        mobilePhoneFieldRef,
        phoneCodes,
        defaultPhoneCode,
        onPhoneCodeChange
    } = useContext(PreRegistrationContext)
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                flexWrap: 'wrap'
        }}
        >
            <Box
                sx={{
                    width: {
                        xs: '100%',
                        md: '50%'
                    },
                    paddingRight: {
                        xs: 0,
                        md: '6px'
                    },
                    marginBottom: {
                        xs: '10px',
                        md: 0
                    }
                    // minWidth: '300px'
                }}
            >
                <CountryPhoneCodeSelect
                    phoneCodes={phoneCodes}
                    onPhoneCodeChange={onPhoneCodeChange}
                    defaultPhoneCode={defaultPhoneCode}
                    values={values}
                    formik={formik}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: {
                        xs: '100%',
                        md: '50%'
                    },
                }}
            >
                <TextField
                    {...textfieldProps(props, t)}
                    type={field.inputType}
                    defaultValue={value}
                    onKeyUp={(textValue) => {
                        let countryPhoneCode = findCountryCode(values.countryPhoneCode, values.mobilePhone, phoneCodes)
                        formik.setFieldValue('fieldValues.countryPhoneCode', countryPhoneCode)
                    }}
                    onBlur={handleBlur}
                    inputRef={mobilePhoneFieldRef}
                    onChange={(e) => {
                        formik.setFieldValue("fieldValues." + field.id, mobilePhoneFieldRef.current.value)
                    }}
                    InputLabelProps={{shrink: true}}
                />
            </Box>
        </Box>
    );
};

export default MobilePhone