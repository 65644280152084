import {eventStatus} from "./date";

export const visitorBodyParameters = ({exhibitionId}, values) => {
    return ({
        ExhibitionId: exhibitionId,
        ...(values.id ? {ContactId: values.id} : {}),
        ...(values.characterization ? {CharacterizationId: values.characterization && values.characterization.id ? values.characterization.id : ""} : {}),
        ...(values.city ? {City: values.city} : {}),
        ...(values.companyAddress ? {CompanyAddress: values.companyAddress} : {}),
        ...(values.companyName ? {CompanyName: values.companyName} : {}),
        ...(values.companyWebsite ? {CompanyWebSite: values.companyWebsite} : {}),
        ...(values.facebook ? {Facebook: values.facebook} : {}),
        ...(values.instagram ? {Instagram: values.instagram} : {}),
        ...(values.countries ? {CountryId: values.countries && values.countries.id ? values.countries.id : ''} : {}),
        ...(values.email ? {email: values.email} : {}),
        ...(values.hasRevisited ? {hasRevisited: values.hasRevisited} : {}),
        ...(values.interests ? {Interests: values.interests.length > 0 ? values.interests.map(v => v.id).toString() : ""} : {}),
        ...(values.isIndividual ? {isIndividual: values.isIndividual} : {}),
        ...(values.key ? {key: values.key} : {}),
        ...(values.mobilePhone ? {MobilePhone: values.mobilePhone} : {}),
        ...(values.name ? {Name: values.surname ? values.name + ' ' + values.surname : values.name} : {}),
        ...(values.otherInterests ? {OtherInterests: values.otherInterests} : {}),
        ...(values.personCount ? {PersonCount: values.personCount} : {}),
        ...(values.phone ? {Phone: values.phone} : {}),
        ...(values.position ? {Position: values.position} : {}),
        ...(values.postalCode ? {PostalCode: values.postalCode} : {}),
        ...(values.vatNumber ? {VatNumber: values.vatNumber} : {}),
    })
}

export const findCountryCode = (currentPhoneCode, mobilePhone, phoneCodes) => {
    let countryPhoneCode = null
    if(!String(mobilePhone).startsWith(currentPhoneCode)) {
        let found = false;
        let foundAt = 0;
        for(let i = 0; i < phoneCodes.length; i++) {
            if(phoneCodes[i].phoneCode !== '' && mobilePhone?.startsWith(phoneCodes[i].phoneCode)) {
                if(!found){
                    foundAt = i;
                    found = true;
                }
                else{
                    found = false;
                    break;
                }
            }
        }
        if(found) {
            countryPhoneCode = phoneCodes[foundAt]
        }
    }
    return countryPhoneCode
}

export const preRegistrationIsOpen = exhibition => {
    const exhibitionStatus = eventStatus(exhibition.dateFrom, exhibition.dateTo)
    const preregistrationStatus = eventStatus(exhibition.preregistrationFrom, exhibition.preregistrationTo)
    return !(!exhibition.preregistrationEnabled || exhibitionStatus === eventStatus.status.EXPIRED || preregistrationStatus === eventStatus.status.EXPIRED)
}