//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultiple} from "../../helpers/parse";
import {parseCountries} from "../../types/countries/parse";
import type {Countries} from "../../types/countries/type";
import _ from "lodash";
import {PreregistrationRegions} from "../../helpers/constants";

const selectLoading = createSelector(
    (state) => state.countries.get('loading'),
    (item) => item
)

const selectItems = createSelector(
    [
        (state) => state.countries.get('byId'),
        (state) => state.currentUser.get('language')
    ],
    (byId, userLanguage) => parseMultiple(byId, (i) => parseCountries(i, userLanguage))
)

export const useCountriesData = ({exhibition}) => {
    const loading: boolean = useSelector(selectLoading)
    const countries: Array<Countries> = useSelector(selectItems)
    const sortCountries = _.sortBy(countries, ['description'])
    const phoneCodes = _.sortBy(sortCountries.map(country => ({
        id: `${country.id}`,
        country: country.description,
        description: `(+${country.phoneCode}) ${country.description}`,
        phoneCode: country.phoneCode,
        countryCode: country.countryCode,
        image: country.image
    })), ['country'])
    let sortedCountries = []
    let defaultPhoneCode = phoneCodes.find(f => f.countryCode === 'GR')
    let defaultCountry = sortCountries.find(f => f.countryCode === 'GR')
    if(exhibition?.preregistrationCountry === PreregistrationRegions.GreeceOnly) {
        sortedCountries = sortCountries.filter(f => f.countryCode === 'GR')
    } else if(exhibition?.preregistrationCountry === PreregistrationRegions.AbroadOnly) {
        sortedCountries = sortCountries.filter(f => f.countryCode !== 'GR')
        defaultPhoneCode = phoneCodes.find(f => f.countryCode === 'EN')
        defaultCountry = sortCountries.find(f => f.countryCode === 'EN')
    } else {
        sortedCountries = sortCountries
    }
    return {
        loading,
        phoneCodes,
        sortedCountries,
        defaultCountry,
        defaultPhoneCode
    }
}