import React from 'react';
import {normalizeGreek} from "../../helpers/normalizeGreek";
import Toolbar from "@mui/material/Toolbar";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox, Stack} from "@mui/material";
import {EXHIBITION} from "../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from "@mui/lab/LoadingButton";
import RestoreIcon from '@mui/icons-material/Restore';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';

type Props = {
    loading: boolean,
    handleInfoPopOver: Function,
    submitForm: Function,
    formik: Object
};

const FormToolbar = ({loading, formik, submitForm, handleInfoPopOver}: Props) => {
    const {t} = useTranslation(EXHIBITION)
    return (
        <Toolbar disableGutters variant={"dense"}>
            <Stack direction="row" spacing={'10px'} sx={{flexWrap: 'wrap', width: '100%'}}>
                <LoadingButton
                    size={"small"}
                    startIcon={<FactCheckIcon/>}
                    variant="contained"
                    color="success"
                    onClick={submitForm}
                    sx={{minHeight: '40px', marginRight: 'auto', minWidth: '120px'}}
                    loading={loading}
                    loadingPosition="start"
                >
                    {normalizeGreek(t('Register'))}
                </LoadingButton>
                <LoadingButton
                    size={"small"}
                    startIcon={<InfoIcon/>}
                    variant="contained"
                    color="warning"
                    onClick={(evt) => handleInfoPopOver({
                        anchorEl: evt.currentTarget,
                        open: true
                    })}
                    sx={{
                        minHeight: '40px',
                        minWidth: '120px',
                        backgroundColor: '#212121'
                    }}
                    loading={loading}
                    loadingPosition="start"
                >
                    {normalizeGreek(t('Information'))}
                </LoadingButton>
            </Stack>
        </Toolbar>
    );
};

export default FormToolbar