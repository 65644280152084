import {useEffect, useState} from "react";
import axios from "axios";
import {IP_INFO_TOKEN, PreregistrationRegions} from "../../helpers/constants";

const useVisitorValidIp = ({exhibition, isPreview}) => {
    const [loading, setLoading] = useState(false)
    const [visitorIpIsValid, setVisitorIpIsValid] = useState(true)

    useEffect(() => {
        setLoading(true)
        if (exhibition && exhibition.preregistrationCountry !== PreregistrationRegions.Everywhere && !isPreview) {
            axios({
                url: `https://ipinfo.io/json?token=${IP_INFO_TOKEN}`,
                method: 'get',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => {
                if (Object.keys(response.data).length > 0) {
                    if(exhibition.preregistrationCountry === PreregistrationRegions.GreeceOnly && response.data.country !== 'GR') {
                        setVisitorIpIsValid(false)
                    }
                    if(exhibition.preregistrationCountry === PreregistrationRegions.AbroadOnly && response.data.country === 'GR') {
                        setVisitorIpIsValid(false)
                    }
                }
                setLoading(false)
            }).catch((error) => {
                setVisitorIpIsValid(false)
                setLoading(false)
            })
        }
    }, [exhibition?.preregistrationCountry])

    return {
        loading,
        visitorIpIsValid
    }
}

export default useVisitorValidIp