import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple} from "../../helpers/map";
import {mapVisitorCategory} from "../../types/categories/map";

const INITIAL_STATE = {
    loading: false,
    byId: []
}

function getVisitorCategories(state) {
    return state
        .set('loading', true)
}

function getVisitorCategoriesSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapVisitorCategory(payload)
    return state
        .set('byId', fromJS(mappedData))
        .set('loading', false)
}

function getVisitorCategoriesFailed(state) {
    return state
        .set('loading', false)
}

const categories = {
    initialState: INITIAL_STATE, handlers: {
        [ActionTypes.GET_VISITOR_CATEGORIES]: getVisitorCategories,
        [ActionTypes.GET_VISITOR_CATEGORIES_SUCCEEDED]: getVisitorCategoriesSucceeded,
        [ActionTypes.GET_VISITOR_CATEGORIES_FAILED]: getVisitorCategoriesFailed,
    } ,
}

export default categories