// @flow
import React, {useEffect, useRef, useState} from 'react';
import useUserAction from "../../hooks/user/useUserAction";
import "./languageSelect.css"
import useUserData from "../../hooks/user/useUserData";
import {CatalogueLanguages, Languages} from "../../helpers/constants";
import {LanguageOutlined} from "@mui/icons-material";
import {alpha, ClickAwayListener, Grid, ListItemButton, Paper, Popper, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Transitions from "../@extended/transitions";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Images from "../../assets/images";
import Button from "@mui/material/Button";

const LanguageSelect = (props) => {
	const {catalogueLanguage, urlLanguage} = props
	const {setUserLanguage} = useUserAction()
	const {language} = useUserData()
	const theme = useTheme();
	const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
	const {i18n} = useTranslation()
	useEffect(() => {
		if (catalogueLanguage !== CatalogueLanguages.EN && urlLanguage && (urlLanguage === 'el' || urlLanguage === 'en')) setUserLanguage(urlLanguage)
	}, [])
	useEffect(() => {
		i18n.changeLanguage(language)
	}, [i18n, language])
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};
	const handleListItemClick = (lang) => {
		setUserLanguage(lang);
		// setOpen(false);
	};
	
	return catalogueLanguage !== CatalogueLanguages.EN && (
		<Box sx={{flexShrink: 0, ml: 0.75, zIndex: 1}}>
			<Button
				aria-label="open localization"
				ref={anchorRef}
				aria-controls={open ? 'localization-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				size={'large'}
				startIcon={<LanguageOutlined/>}
				sx={{fontWeight: 'bold', minWidth: '100px', color: '#0E3B43'}}
				variant={'outlined'}
			>
				<Typography
					pl={1}
					component={'span'}
					variant={'button'}
				>
					{language === Languages.EN ? 'EN' : 'EL'}
				</Typography>
			</Button>
			<Popper
				placement={matchesXs ? 'bottom-start' : 'bottom'}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [matchesXs ? 0 : 0, 9]
							}
						}
					]
				}}
			>
				{({TransitionProps}) => (
					<Transitions type="grow" position={matchesXs ? 'top-right' : 'top'} in={open} {...TransitionProps}>
						<Paper sx={{boxShadow: `0px 1px 4px ${alpha(theme.palette.grey[900], 0.08)}`}}>
							<ClickAwayListener onClickAway={handleClose}>
								<List
									component="nav"
									sx={{
										p: 0,
										width: '100%',
										minWidth: 200,
										maxWidth: 290,
										bgcolor: theme.palette.background.paper,
										borderRadius: 0.5,
										[theme.breakpoints.down('md')]: {
											maxWidth: 250
										}
									}}
								>
									<ListItemButton selected={language === Languages.EN}
													onClick={() => handleListItemClick(Languages.EN)}>
										<ListItemText
											primary={
												<Grid container alignItems={'center'} gap={1}>
													<img src={Images.englishFlag} alt={'English flag'} width={'30px'}/>
													<Typography color="textPrimary">English</Typography>
													<Typography variant="caption" color="textSecondary"
																sx={{ml: '8px'}}>
														(EN)
													</Typography>
												</Grid>
											}
										/>
									</ListItemButton>
									<ListItemButton selected={language === Languages.EL}
													onClick={() => handleListItemClick(Languages.EL)}>
										<ListItemText
											primary={
												<Grid container alignItems={'center'} gap={1}>
													<img src={Images.greekFlag} alt={'Greek flag'} width={'30px'}/>
													<Typography color="textPrimary">Ελληνικά</Typography>
													<Typography variant="caption" color="textSecondary"
																sx={{ml: '8px'}}>
														(EL)
													</Typography>
												</Grid>
											}
										/>
									</ListItemButton>
								</List>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</Box>
	)
}

export default LanguageSelect;