// @flow
import React from 'react';
import Box from "@mui/material/Box";

const AgreeToTerms = () => {
    return (
        <Box sx={{maxWidth: '900px'}}>
            <h1>PRIVACY POLICY</h1>
            <hr/>
            <p>Of the public limited company under the name <strong>“ROTA SA”</strong>, having its registered office in
                Pallini, Attica, 4 Anthoussas Ave., which owns the website with the domain name: www.defea.gr/
                (hereinafter referred to as the Website)</p>
            <ol>
                <li>
                    <strong>What are Personal Data:</strong>
                    <p>Any information relating to an identified or identifiable natural person (“data subject”); an
                        identifiable natural person is a person who can be identified, directly or indirectly, in
                        particular by reference to an identifier such as a name, an identification number, location
                        data, an online identifier or to one or more factors specific to the physical, physiological,
                        genetic, mental, economic, cultural or social identity of that natural person.</p>
                </li>
                <li>
                    <strong>What is Personal Data Processing:</strong>
                    <p>Any operation or set of operations which is performed on personal data or on sets of personal
                        data, whether or not by automated means, such as collection, recording, organisation,
                        structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by
                        transmission, dissemination or otherwise making available, alignment or combination,
                        restriction, erasure or destruction.</p>
                </li>
                <li>
                    <strong>Which Data do we collect:</strong>
                    <p>For simple browsing of our Website we do not collect any of your personal data.<br/>We collect
                        your detailed information if you choose to pre-register for your visit to our event, or your
                        email if you ask us to be subscribed to our newsletter.</p>
                </li>
                <li>
                    <strong>Purpose of processing your data:</strong>
                    <p>The purpose of processing your Data is to send to you the personalised invitation for your
                        attendance to any of our events or our newsletter to inform you of our events.</p>
                </li>
                <li>
                    <strong>Legal basis for the processing of your Data by our Company:</strong>
                    <p>Processing of your Data is performed in accordance with our mutual agreement upon your request
                        for your pre-registration for your attendance to our event or for informational emails to be
                        sent to you.</p>
                </li>
                <li>
                    <strong>Recipients of Your Data:</strong>
                    <p>The recipients of the data are the following categories:</p>
                    <ul>
                        <li>Our Company, i.e. the public limited company under the name <strong>“ROTA SA” and its
                            subsidiaries</strong>, having their registered offices in Pallini, Attica, 4 Anthoussas
                            Ave., as well as our authorised partners who are implementing your pre-registration
                            process.<br/>If you have any questions about your Personal Data processed by our
                            company, you may freely contact us at the following email: <a
                                href="mailto: gdpr@rota.gr">gdpr@rota.gr</a></li>
                    </ul>
                    <br/>
                </li>
                <li>
                    <strong>Our Company and Your Personal Data:</strong>
                    <p>Our company:</p>
                    <ul>
                        <li>Maintains confidentiality about the personal data you share with us</li>
                        <li>Does not transmit your data to third parties, besides those referred to in Article 6
                            hereof,
                            without your written permission.
                        </li>
                        <li>Takes organisational and technical security measures for data processing that protect
                            the
                            reasonable and physical security thereof, such as secure software, physical protection,
                            pseudonyms, encryption.
                        </li>
                        <li>Our systems by design and by definition ensure that we will be able to fulfil our
                            obligations under the General Data Protection Regulation (GDPR).
                        </li>
                        <li>We will immediately notify you of any incident of violation of your personal
                            information.
                        </li>
                    </ul>
                    <br/>
                </li>
                <li>
                    <strong>Transmission of your Data abroad:</strong>
                    <p>Your Personal Data are stored and processed only within Greece. They are NOT sent abroad.</p>
                </li>
                <li>
                    <strong>Retention time – Deletion of your Data:</strong>
                    <p>Our company keeps your data in an electronic file until the expiration of our mutual information
                        agreement. Your data is then permanently deleted without recoverability.</p>
                </li>
                <li>
                    <strong>Security of Your Data:</strong>
                    <p>Any processing of your Data, in any way whatsoever, is only allowed to our authorised employees
                        who are bound to keep confidentiality regarding the processing of your personal data and solely
                        for the purposes mentioned above.<br/><br/>We have taken the necessary and appropriate
                        organisational and technical measures to secure and protect your Data from any form of
                        accidental or fraudulent processing both on a physical level and at a reasonable security level
                        (for example physical security procedures, graded access, protection of computer software
                        systems and network equipment).<br/><br/>These measures shall be reviewed and amended whenever
                        appropriate.</p>
                </li>
                <li>
                    <strong>What are your rights:</strong>
                    <ul>
                        <li>
                            <span>Right to information</span>
                            <p>Our Company takes all and any necessary action both during the collection stage and in
                                any subsequent processing stage of your personal data so that you may be fully able to
                                exercise your legal rights as described in this briefing.<br/><br/>At the stage of
                                collecting your data, from the beginning of our contractual relationship (agreement to
                                participate in the exhibition), you acknowledge you are aware of the present as of the
                                date of your participation declaration. After that you may consult this briefing text on
                                our website.</p>
                        </li>
                        <li>
                            <span>Right of access to your personal data.</span>
                            <p>This means that you have the right to be informed by us on how and if we process your
                                Data. If we process your Data, you may ask to be informed about the purpose of the
                                processing, the type of your Data we keep, whom do we give it to, how long we store
                                them, whether automated decision making takes place, but also about your other rights,
                                such as rectification or erasure of data, restriction of processing and submitting a
                                complaint to the Data Protection Authority.</p>
                        </li>
                        <li>
                            <span>Right to rectify inaccurate personal data.</span>
                            <p>If you find that there is an error in your Data, you may submit a request to correct it
                                (e.g., correction of the name) at the following email address: <a
                                    href="mailto: gdpr@rota.gr">gdpr@rota.gr</a></p>
                        </li>
                        <li>
                            <span>Right to erasure.</span>
                            <p>You may ask us to delete your data if they are no longer necessary for the above
                                mentioned processing purposes or you wish to withdraw your consent in case you have
                                previously submitted such consent.</p>
                        </li>
                        <li>
                            <span>Right to Data portability</span>
                            <p>You may ask that you receive from us in a readable form the Data you have provided or ask
                                us to forward them to another controller.</p>
                        </li>
                        <li>
                            <span>Right to restrict processing.</span>
                            <p>You may ask us to restrict the processing of your Data for as long as the review of your
                                objections as regards the processing is pending.</p>
                        </li>
                        <li>
                            <span>Right to object to the processing of your Data.</span>
                            <p>You may object to the processing of your Data and we will immediately stop processing
                                your Data unless there are other legitimate reasons that take precedence.</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>How to exercise your rights:</strong>
                    <p>For any request and exercise of rights, in relation to your personal data, you may contact us by
                        e-mail at: <a href="mailto: gdpr@rota.gr">gdpr@rota.gr</a></p>
                </li>
                <li>
                    <strong>Response Time to Your Requests:</strong>
                    <p>We reply to your Requests for free without delay, and in any case within (1) one month after we
                        receive your Request. However, if your Request is complex or there are too many Requests to
                        process, we will notify you within one month if we need an additional period of two (2) months,
                        within which we will respond to you.<br/><br/>If your Requests are manifestly unfounded or
                        excessive, in particular due to their recurring nature, the Company may impose a reasonable fee,
                        taking into account the administrative costs of providing information or performing the
                        requested action, or refuse to further process the Request by giving you a reasoned
                        reply.<br/><br/>To inquire about the processing stage of your requests, you may contact us at
                        the following e-mail address: <a href="mailto: gdpr@rota.gr">gdpr@rota.gr</a></p>
                </li>
                <li>
                    <strong>Automated decision making – profile:</strong>
                    <p>We do not make decisions, nor do we make profiling, based on the automated processing of your
                        Data.</p>
                </li>
                <li>
                    <strong>Complaint to the Personal Data Protection Authority:</strong>
                    <p>You have the right to file a complaint with the Personal Data Protection Authority located in
                        Athens, 1-3 Kifissias Avenue, Postal Code 115 23, tel. +30 210 6475600, FAX +30 210 6475628. <a
                            href="mailto: complaints@dpa.gr">complaints@dpa.gr</a>, if you think that the processing of
                        your Personal Data violates the applicable national and regulatory framework for the protection
                        of personal data. You can find more information about filing a complaint at the following
                        link: <a
                            href="http://www.dpa.gr/portal/page?_pageid=33,211532&_dad=portal&_schema=PORTAL">http://www.dpa.gr/portal/page?_pageid=33,211532&_dad=portal&_schema=PORTAL</a>
                    </p>
                </li>
                <li>
                    <strong>Update for amendments hereto:</strong>
                    <p>We update this Policy whenever necessary. If there are any significant changes to the Policy or
                        to the way we use your Personal Data, we will post it to www.rota.gr on our website before the
                        changes come into force and we will notify you in any appropriate manner. We encourage you to
                        read this Policy at regular intervals to know how your Data are protected.</p>
                </li>
            </ol>
        </Box>
    )
};

export default AgreeToTerms