import {useEffect, useRef, useState} from "react";
import {usePreregistrationForm} from "./usePreRegistrationForm";
import useUserAction from "../user/useUserAction";
import useUserData from "../user/useUserData";

export const useForm = ({
                            id,
                            exhibition,
                            countries,
                            interests,
                            phoneCodes,
                            defaultCountry,
                            defaultPhoneCode,
                            categories,
                            personalLinkVisitorKey
                        }) => {
    const {loading, errorRegister, registeredSuccessfully} = useUserData()
    const {userInviteRegister, clearRegisteredSuccessfullyFlag} = useUserAction()
    const [filledByTaxisSearch, setFilledByTaxisSearch] = useState({
        companyName: false,
        companyAddress: false,
        vatNumber: false,
        city: false,
        postalCode: false
    })

    const companyNameFieldRef = useRef(null)
    const nameFieldRef = useRef(null)
    const surnameFieldRef = useRef(null)
    const vatFieldRef = useRef(null)
    const emailFieldRef = useRef(null)
    const mobilePhoneFieldRef = useRef(null)
    const phoneFieldRef = useRef(null)
    const companyAddressFieldRef = useRef(null)
    const cityFieldRef = useRef(null)
    const companyWebsiteFieldRef = useRef(null)
    const facebookFieldRef = useRef(null)
    const instagramFieldRef = useRef(null)
    const positionFieldRef = useRef(null)
    const personNumberFieldRef = useRef(null)
    const postalCodeFieldRef = useRef(null)
    const otherInterestsFieldRef = useRef(null)

    const resetForm = () => {
        formik.resetForm()
        if (vatFieldRef.current?.value) {
            vatFieldRef.current.value = ""
        }
        if (companyNameFieldRef.current?.value) {
            companyNameFieldRef.current.value = ""
        }
        if (emailFieldRef.current?.value) {
            emailFieldRef.current.value = ""
        }
        if (mobilePhoneFieldRef.current?.value) {
            mobilePhoneFieldRef.current.value = 30
        }
        if (phoneFieldRef.current?.value) {
            phoneFieldRef.current.value = ""
        }
        if (companyAddressFieldRef.current?.value) {
            companyAddressFieldRef.current.value = ""
        }
        if (cityFieldRef.current?.value) {
            cityFieldRef.current.value = ""
        }
        if (companyWebsiteFieldRef.current?.value) {
            companyWebsiteFieldRef.current.value = ""
        }
        if (instagramFieldRef.current?.value) {
            instagramFieldRef.current.value = ""
        }
        if (facebookFieldRef.current?.value) {
            facebookFieldRef.current.value = ""
        }
        if (positionFieldRef.current?.value) {
            positionFieldRef.current.value = ""
        }
        if (personNumberFieldRef.current?.value) {
            personNumberFieldRef.current.value = 1
        }
        if (postalCodeFieldRef.current?.value) {
            postalCodeFieldRef.current.value = ""
        }
        if (otherInterestsFieldRef.current?.value) {
            otherInterestsFieldRef.current.value = ""
        }
        if (surnameFieldRef.current?.value) {
            surnameFieldRef.current.value = ""
        }
        nameFieldRef.current.value = ""
    }

    const onSubmit = (values) => {
        userInviteRegister(id, {
            ...values.fieldValues,
            key: personalLinkVisitorKey,
            otherInterests: otherInterestsFieldRef.current?.value,
        })
    }

    const {
        formik,
        fieldValues,
        fields
    } = usePreregistrationForm({
        exhibition: exhibition,
        onSubmit: onSubmit,
        useLocalStorage: true,
        isFromRegistration: false,
        countries: countries,
        defaultCountry: defaultCountry,
        defaultPhoneCode: defaultPhoneCode,
        categories: categories
    })

    const submitForm = (evt) => {
        formik.submitForm(evt)
    }

    const onPhoneCodeChange = (event, newValue) => {
        formik.setFieldValue('fieldValues.countryPhoneCode', newValue)
        if (newValue) {
            mobilePhoneFieldRef.current.value = newValue.phoneCode
        }
    }

    return {
        loading,
        registeredSuccessfully,
        errorRegister,
        companyNameFieldRef,
        nameFieldRef,
        surnameFieldRef,
        vatFieldRef,
        emailFieldRef,
        mobilePhoneFieldRef,
        phoneFieldRef,
        companyAddressFieldRef,
        cityFieldRef,
        companyWebsiteFieldRef,
        instagramFieldRef,
        facebookFieldRef,
        positionFieldRef,
        personNumberFieldRef,
        postalCodeFieldRef,
        otherInterestsFieldRef,
        formik,
        fields,
        fieldValues,
        resetForm,
        submitForm,
        onPhoneCodeChange,
        filledByTaxisSearch,
        setFilledByTaxisSearch
    }
}