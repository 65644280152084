import moment from "moment";

const formatTypes = {
    DEFAULT: 0,
    TITLE: 1,
    DATE_ONLY: 2,
    DATE_ONLY_LONG: 3,
    TITLE_HALF: 4,
    TIME_ONLY: 5,
    DAY: 6,
    MONTH: 7,
    MONTH_DOUBLE: 9,
    YEAR: 8,
    DATE_ONLY_FILTER: 10,
    DATE_AND_TIME: 11,
    DATE_DATABASE_FORMAT: 12
}

export const formatTime = (time) => {
    const momentTime = moment(time, "HH:mm:ss")
    return momentTime.format("HH:mm")
}

export const formatDate = (date: Date, format: number = formatTypes.DEFAULT) => {
    const momentDate = moment(date)
    switch (format) {
        case formatTypes.DATE_ONLY_LONG:
            return momentDate.format('D MMM YYYY')
        case formatTypes.DATE_AND_TIME:
            return momentDate.format('DD/MM/YYYY HH:mm:ss')
        case formatTypes.DATE_ONLY:
            return momentDate.format('DD/MM/YYYY')
        case formatTypes.TITLE:
            return momentDate.format('dddd, D MMMM yyyy HH:mm:ss')
        case formatTypes.TIME_ONLY:
            return momentDate.format('HH:mm:ss')
        case formatTypes.TITLE_HALF:
            return momentDate.format('dddd, D MMMM yyyy')
        case formatTypes.DAY:
            return momentDate.format('DD')
        case formatTypes.MONTH:
            return momentDate.format('M')
        case formatTypes.MONTH_DOUBLE:
            return momentDate.format('MM')
        case formatTypes.DATE_ONLY_FILTER:
            return momentDate.format('YYYY-MM-DD')
        case formatTypes.YEAR:
            return momentDate.format('YYYY')
        case formatTypes.DEFAULT:
        default:
            return momentDate.format('dddd, D MMMM yyyy')
    }
}

formatDate.formatTypes = formatTypes

export const EventStatus = {
    EXPIRED: 1,
    UPCOMING: 2,
    ONGOING: 3,
}

export const eventStatus = (dateFrom, dateTo) => {
    const isExpired = moment().isAfter(dateTo, 'day')
    const isUpcoming = moment().isBefore(dateFrom, 'day')
    return isExpired ? EventStatus.EXPIRED : isUpcoming ? EventStatus.UPCOMING : EventStatus.ONGOING
}

eventStatus.status = EventStatus