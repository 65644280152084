// @flow
import React from 'react';
import {textfieldProps} from "./text";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";

type Props = {

};

const FieldText = (props: Props) => {
	const {t} = useTranslation(VISITOR_PROFILE)
	const {field, value, handleBlur, fieldRef, formik, disabled} = props
	return (
		<TextField
			{...textfieldProps(props, t)}
			type={field.inputType}
			defaultValue={value}
			disabled={disabled}
			onBlur={handleBlur}
			inputRef={fieldRef}
			autoComplete='off'
			onChange={(e) => {
				fieldRef.current.value = (field.type !== 'email' && field.type !== 'companyWebsite' && field.type !== 'instagram' && field.type !== 'facebook') ? normalizeGreek(fieldRef.current.value.toUpperCase()) : fieldRef.current.value
				formik.setFieldValue('fieldValues.' + field.id, fieldRef.current.value)
			}}
			InputLabelProps={{shrink: true}}
			inputProps={{maxLength: field?.maxLength}}
		/>
	);
};

export default FieldText