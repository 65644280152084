// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";

export const useCategoriesAction = (id) => {
    const dispatch = useDispatch()
    const getVisitorCategories = useCallback( () => {
        dispatch(actions.getVisitorCategories({
            showPreregistration: 1,
            id: id
        }))
    }, [dispatch])

    return {
        getVisitorCategories
    }
}