// @flow
import * as React from 'react';
import Box from "@mui/material/Box";
import RenderField from "../renderField/renderField";
import {useContext} from "react";
import {PreRegistrationContext} from "../form/form";
import RenderFieldInGroups from "../renderFieldInGroups/renderFieldInGroups";

type Props = {};

const RenderFieldArray = (props: Props) => {
    const {fields, formik, values, handleBlur, handleChange, touched, errors} = props
    const {exhibition} = useContext(PreRegistrationContext)
    const preregistrationHasFieldGroups = exhibition.preregistrationHasFieldGroups

    return !preregistrationHasFieldGroups ? (
        fields
            .map(f => (
                <Box
                    sx={{mb: 1}}
                    key={f.id}
                >
                    <RenderField
                        formik={formik}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        fields={fields}
                        field={f}
                    />
                </Box>
            ))
    ) : (
        <RenderFieldInGroups
            formik={formik}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            fields={fields}
        />
    )
};

export default RenderFieldArray