import {actionCreator} from '../helpers/actionCreator'
import * as ActionTypes from './types'
export * from './currentUser/actions'

export const getExhibitionDetails = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS)
export const getExhibitionDetailsSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_SUCCEEDED)
export const getExhibitionDetailsFailed = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_FAILED)

export const getCountries = actionCreator(ActionTypes.GET_COUNTRIES)
export const getCountriesSucceeded = actionCreator(ActionTypes.GET_COUNTRIES_SUCCEEDED)
export const getCountriesFailed = actionCreator(ActionTypes.GET_COUNTRIES_FAILED)

export const getInterests = actionCreator(ActionTypes.GET_INTERESTS)
export const getInterestsSucceeded = actionCreator(ActionTypes.GET_INTERESTS_SUCCEEDED)
export const getInterestsFailed = actionCreator(ActionTypes.GET_INTERESTS_FAILED)

export const createVisitor = actionCreator(ActionTypes.CREATE_VISITOR)
export const createVisitorSucceeded = actionCreator(ActionTypes.CREATE_VISITOR_SUCCEEDED)
export const createVisitorFailed = actionCreator(ActionTypes.CREATE_VISITOR_FAILED)

export const clearActionOnVisitorStatus = actionCreator(ActionTypes.CLEAR_ACTION_ON_VISITOR_STATUS)

export const userInviteRegister = actionCreator(ActionTypes.USER_INVITE_REGISTER)
export const userInviteRegisterSucceeded = actionCreator(ActionTypes.USER_INVITE_REGISTER_SUCCEEDED)
export const userInviteRegisterFailed = actionCreator(ActionTypes.USER_INVITE_REGISTER_FAILED)
export const clearRegisteredSuccessfullyFlag = actionCreator(ActionTypes.CLEAR_REGISTERED_SUCCESSFULLY_FLAG)

export const getVisitorCategories = actionCreator(ActionTypes.GET_VISITOR_CATEGORIES)
export const getVisitorCategoriesSucceeded = actionCreator(ActionTypes.GET_VISITOR_CATEGORIES_SUCCEEDED)
export const getVisitorCategoriesFailed = actionCreator(ActionTypes.GET_VISITOR_CATEGORIES_FAILED)