// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";
import {visitorBodyParameters} from "../../helpers/helpers";
import jsonToFormData from "json-form-data";

const useUserAction = () => {
    const dispatch = useDispatch()

    const setUserLanguage = useCallback((language) => {
        dispatch(actions.setUserLanguage(language))
    }, [dispatch])

    const clearRegisteredSuccessfullyFlag = useCallback((language) => {
        dispatch(actions.clearRegisteredSuccessfullyFlag(language))
    }, [dispatch])

    const userInviteRegister = useCallback((exhibitionId, values) => {
        const dataToSend = visitorBodyParameters({exhibitionId}, values)
        const formData = jsonToFormData(dataToSend)
        dispatch(actions.userInviteRegister(formData))
    }, [dispatch])

    return {
        setUserLanguage,
        userInviteRegister,
        clearRegisteredSuccessfullyFlag,
    }
}

export default useUserAction