//@flow
import React from 'react';
import {Route, Switch} from "react-router";
import {ROUTE_PAGE_INVITATION_REGISTER, ROUTE_PAGE_NOT_FOUND} from "./routes";

const ROUTES = [
    ROUTE_PAGE_INVITATION_REGISTER,
    ROUTE_PAGE_NOT_FOUND
]

const UnauthenticatedRouter = () => {
    return (
        <Switch>
            {ROUTES.map(route => {
                const {key, path, exact, component} = route;
                return (
                    <Route
                        key={key}
                        path={path}
                        exact={exact}
                        component={component}
                    />
                );
            })}
        </Switch>
    );
};

export default UnauthenticatedRouter;