import {routeCreator} from "../helpers/routeCreator";
import NotFound from "../pages/notFound/notFound";
import PreRegistration from "../pages/preRegistration/preRegistration";

export const ROUTE_PAGE_INVITATION_REGISTER = routeCreator(PreRegistration, '/invite-register', 'Register', 'page.users.register', {exact: true})
export const ROUTE_PAGE_NOT_FOUND = routeCreator(NotFound, '/', 'Not Found', 'page.notFound')

const router = {
    ROUTE_PAGE_NOT_FOUND
}

export default router;