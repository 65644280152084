//@flow
import { createSelector } from 'reselect'
import { useSelector } from "react-redux";

const selectLanguage = createSelector(
    (state) => state.currentUser,
    (item) => item.get('language')
)

const selectLoading = createSelector(
    (state) => state.currentUser,
    (item) => item.get('loading')
)

const selectErrorRegister = createSelector(
    (state) => state.currentUser,
    (item) => item.get('errorRegister')
)

const selectRegisteredSuccessfully = createSelector(
    (state) => state.currentUser,
    (item) => item.get('registeredSuccessfully')
)

const useUserData = () => {
    const language = useSelector(selectLanguage)
    const loading = useSelector(selectLoading)
    const errorRegister = useSelector(selectErrorRegister)
    const registeredSuccessfully = useSelector(selectRegisteredSuccessfully)
    return {
        loading,
        language,
        errorRegister,
        registeredSuccessfully,
    }
}

export default useUserData