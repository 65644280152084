// @flow
import * as React from 'react';
import Box from "@mui/material/Box";
import {Popover} from "@mui/material";
import RegistrationInfo from "../registrationInfo/registrationInfo";

type Props = {

};

const InfoPopOver = ({infoPopOver, handleInfoPopOver}: Props) => {
    const handlePopOverClose = () => {
        handleInfoPopOver({anchorEl: null, open: false, body: ''})
    }

    return (
        <React.Fragment>
            <Box onClick={(e) => e.stopPropagation()}>
                <Popover
                    disableRestoreFocus
                    onClose={handlePopOverClose}
                    open={infoPopOver.open}
                    anchorEl={infoPopOver.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <Box sx={{width: '100%', padding: '20px'}}>
                        <RegistrationInfo />
                    </Box>
                </Popover>
            </Box>
        </React.Fragment>
    );
};

export default InfoPopOver