// @flow
import * as React from 'react';
import {Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import {isArray} from "lodash";
import {ErrorMessage} from "formik";
import {useContext} from "react";
import {PreRegistrationContext} from "../../form/form";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

type Props = {};

const Checkbox = (props: Props) => {
    const {formik, values, field, value, handleBlur, handleChange, touched, errors, disabled} = props
    const {t} = useTranslation(VISITOR_PROFILE)
    const checked = (!isArray(value) && !!value) || (isArray(value) && value.length === 0)
    const {handleAgreeToTermsPopOver} = useContext(PreRegistrationContext)
    const label = field.type === 'agreeToTerms' ? (
        <Box>
            <span>{t(field.name)}</span>
            <Button
                variant="text"
                onClick={(evt) => handleAgreeToTermsPopOver({
                    anchorEl: evt.currentTarget,
                    open: true
                })}
            >
                {t('Terms')}
            </Button>
        </Box>
    ) : t(field.name)
    return (
        <FormControl
            error={
                touched["fieldValues"] &&
                touched["fieldValues"][field.id] &&
                Boolean(errors["fieldValues"] && errors["fieldValues"][field.id])
            }
        >
            <FormControlLabel
                control={
                    <MuiCheckbox
                        disabled={disabled}
                        name={"fieldValues." + field.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={field.required}
                        checked={checked}
                        value={checked}
                    />
                }
                error={`
                    ${touched["fieldValues"] &&
                touched["fieldValues"][field.id] &&
                Boolean(errors["fieldValues"] && errors["fieldValues"][field.id])}
                `}
                labelPlacement="end"
                label={label}
            />
            <FormHelperText>
                <ErrorMessage name={"fieldValues." + field.id}/>
            </FormHelperText>
        </FormControl>
    )
};

export default Checkbox