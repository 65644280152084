// @flow
import React from 'react';
import {Checkbox, FormControl, FormControlLabel, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../locales/components/namespaces";

type Props = {};

const DialogField = (props: Props) => {
	const {taxisData, handleDialog, field, label} = props
	return (
		<Grid container alignItems={'center'}>
			<Grid item xs={12} sm={5}>
				<Typography
					variant={'subtitle2'}
					component={'div'}
					ml={1.6}
				>
					{label}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={7}>
				<FormControl
					variant="standard"
					sx={{
						color: '#255b82',
					}}
				>
					<FormControlLabel
						control={
							<Checkbox
								checked={taxisData.data?.[field]?.checked}
								onChange={(evt) => {
									handleDialog({
										...taxisData,
										data: {
											...taxisData.data,
											[field]: {
												...taxisData.data[field],
												checked: evt.target.checked,
											}
										}
									})
								}}
								size={'small'}
								name={field}
							/>
						}
						label={taxisData.data?.[field]?.value}
						labelPlacement="end"
					/>
				</FormControl>
			</Grid>
		</Grid>
	);
};

export default DialogField