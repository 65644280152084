import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {
    mapExhibition,
    mapVisitor,
} from "../../types/exhibition/map";

const INITIAL_STATE = {
    loading: false,
    byId: {},
    actionOnVisitorStatus: null,
}

function getExhibitionDetails(state) {
    return state
        .set('loading', true);
}

function getExhibitionDetailsSucceeded(state, action) {
    const {exhibition} = action.payload
    const unmappedData = {
        ...exhibition[0]
    }
    const mappedData = mapExhibition(unmappedData)
    return state
        .set('loading', false)
        .setIn(['byId', mappedData.id], fromJS(mappedData))
}

function getExhibitionDetailsFailed(state, action) {
    return state
        .set('loading', false);
}

function actionOnVisitor(state) {
    return state
        .set('loading', true)
        .set('actionOnVisitorStatus', null)
}

function actionOnVisitorFailed(state, action) {
    const {message} = action.payload
    return state
        .set('loading', false)
        .set('actionOnVisitorStatus', fromJS({type: 'error', message: message}))
}

function createVisitorSucceeded(state, action) {
    const {payload: {visitor, businessCategs, address, interests}, parameters} = action
    const {ExhibitionId, ExhibitorId} = Object.fromEntries(parameters)
    const unMappedData = {
        ...visitor[0], businessCategories: businessCategs, address, interests: interests
    }
    const mappedData = mapVisitor(unMappedData)
    return state
        .setIn(['visitorById', mappedData.id], fromJS(mappedData))
        .setIn(['exhibitorById', ExhibitorId, 'visitors', mappedData.id], fromJS(mappedData))
        .setIn(['byId', ExhibitionId, 'visitors', mappedData.id], fromJS(mappedData))
        .set('loading', false)
        .set('actionOnVisitorStatus', fromJS({
            type: 'success',
            message: 'Visitor added successfully',
            parameters: mappedData
        }))
}

function clearActionOnVisitorStatus(state) {
    return state
        .set('actionOnVisitorStatus', null)
}

const exhibition = {
    initialState: INITIAL_STATE, handlers: {
        [ActionTypes.GET_EXHIBITION_DETAILS]: getExhibitionDetails,
        [ActionTypes.GET_EXHIBITION_DETAILS_SUCCEEDED]: getExhibitionDetailsSucceeded,
        [ActionTypes.GET_EXHIBITION_DETAILS_FAILED]: getExhibitionDetailsFailed,
        [ActionTypes.CREATE_VISITOR]: actionOnVisitor,
        [ActionTypes.CREATE_VISITOR_SUCCEEDED]: createVisitorSucceeded,
        [ActionTypes.CREATE_VISITOR_FAILED]: actionOnVisitorFailed,
        [ActionTypes.CLEAR_ACTION_ON_VISITOR_STATUS]: clearActionOnVisitorStatus,
    }
}

export default exhibition
