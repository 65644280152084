import {useContext, useState} from "react";
import axios from "axios";
import {API} from "../../helpers/constants";
import useUserData from "../user/useUserData";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../locales/components/namespaces";
import {PreRegistrationContext} from "../../components/form/form";

const initialValue = {
	open: false,
	data: {
		companyName: {
			checked: false,
			value: ''
		},
		companyAddress: {
			checked: false,
			value: ''
		},
		city: {
			checked: false,
			value: ''
		},
		postalCode: {
			checked: false,
			value: ''
		}
	}
}

const useTaxisSearch = ({formik, setFilledByTaxisSearch, filledByTaxisSearch}) => {
	const {token} = useUserData()
	const {
		companyNameFieldRef,
		companyAddressFieldRef,
		cityFieldRef,
		postalCodeFieldRef,
		defaultCountry
	} = useContext(PreRegistrationContext)
	const {t} = useTranslation(VISITOR_PROFILE)
	const [loading, setLoading] = useState(false)
	const [taxisData, setTaxisData] = useState(initialValue)
	const handleTaxisData = (newValue) => {
		setTaxisData({...newValue});
	};
	const taxisSearch = (taxRegNo) => {
		if (taxRegNo.length === 9) {
			setLoading(true)
			axios({
				url: API + '/en/visitor/search-taxis/' + taxRegNo,
				method: 'get',
				headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			}).then((response) => {
				setLoading(false)
				const taxisData = response?.data?.data
				if (taxisData && taxisData?.onomasia) {
					setTaxisData({
						open: true,
						data: {
							companyName: {
								checked: true,
								value: taxisData.onomasia
							},
							companyAddress: {
								checked: true,
								value: taxisData.postaddr + ', ' + taxisData.postaddrno,
							},
							city: {
								checked: true,
								value: taxisData.postarea
							},
							postalCode: {
								checked: true,
								value: taxisData.postzipcode
							}
						}
					})
				} else {
					enqueueSnackbar(t('Tax Reg. No. not found on TAXIS!'), {
						variant: 'warning',
						autoHideDuration: 2000,
					})
				}
			}).catch((error) => {
				setLoading(false)
				enqueueSnackbar(handleAxiosErrors(error, t('Failed to search Tax Reg. No. on TAXIS!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
			})
		} else {
			enqueueSnackbar(t('Insert exactly 9 characters to search registration number on TAXIS!'), {
				variant: 'warning',
				autoHideDuration: 2000,
			})
		}
	}
	
	const handleSelections = () => {
		const updateFilledByTaxisSearch = {
			companyName: false,
			companyAddress: false,
			vatNumber: false,
			city: false,
			postalCode: false
		}
		if (companyNameFieldRef.current && taxisData.data?.companyName?.checked) {
			companyNameFieldRef.current.value = taxisData.data?.companyName?.value
			formik.setFieldValue('fieldValues.companyName', taxisData.data?.companyName?.value)
			updateFilledByTaxisSearch.companyName = true
		}
		if (companyAddressFieldRef.current && taxisData.data?.companyAddress?.checked) {
			companyAddressFieldRef.current.value = taxisData.data?.companyAddress?.value
			formik.setFieldValue('fieldValues.companyAddress', taxisData.data?.companyAddress?.value)
			updateFilledByTaxisSearch.companyAddress = true
		}
		if (cityFieldRef.current && taxisData.data?.city?.checked) {
			cityFieldRef.current.value = taxisData.data?.city?.value
			formik.setFieldValue('fieldValues.city', taxisData.data?.city?.value)
			updateFilledByTaxisSearch.city = true
		}
		if (postalCodeFieldRef.current && taxisData.data?.postalCode?.checked) {
			postalCodeFieldRef.current.value = taxisData.data?.postalCode?.value
			formik.setFieldValue('fieldValues.postalCode', taxisData.data?.postalCode?.value)
			updateFilledByTaxisSearch.postalCode = true
		}
		formik.setFieldValue('fieldValues.countries', defaultCountry)
		setTaxisData(initialValue)
		setFilledByTaxisSearch({
			...filledByTaxisSearch,
			...updateFilledByTaxisSearch,
			vatNumber: updateFilledByTaxisSearch.companyName || updateFilledByTaxisSearch.companyAddress || updateFilledByTaxisSearch.city || updateFilledByTaxisSearch.postalCode
		})
	}
	
	return {
		taxisSearch,
		loading,
		taxisData,
		handleTaxisData,
		handleSelections
	}
}

export default useTaxisSearch