import type {Exhibition} from "./types";
import {withLanguage} from "../../helpers/parse";

export const parseExhibition: Exhibition = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        title: withLanguage(language)(datum.get('title')),
        image: withLanguage(language)(datum.get('image')),
        catalogueLanguage: datum.get('catalogueLanguage'),
        preregistrationRedirect: datum.get('preregistrationRedirect'),
        preregistrationFrom: datum.get('preregistrationFrom'),
        preregistrationTo: datum.get('preregistrationTo'),
        dateFrom: datum.get('dateFrom'),
        dateTo: datum.get('dateTo'),
        preregistrationEnabled: datum.get('preregistrationEnabled'),
        preregistrationHasFieldGroups: datum.get('preregistrationHasFieldGroups'),
        preregistrationFormFields: datum.get('preregistrationFormFields')?.toJS(),
        sendEmailAfterPreregistration: datum.get('sendEmailAfterPreregistration'),
        sendSMSAfterPreregistration: datum.get('sendSMSAfterPreregistration'),
        preregistrationCountry: datum.get('preregistrationCountry'),
        googleTagCode: datum.get('googleTagCode'),
    }
}