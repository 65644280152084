/* eslint-disable react-hooks/exhaustive-deps */
//@flow
import React, {useEffect} from 'react'
import useUserData from "../../hooks/user/useUserData";
import moment from "moment";
import 'moment/locale/el'
import './main.css';
import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";

const Main = () => {
    const {language} = useUserData()
    useEffect(() => {
        moment.locale(language);
    }, [language])
    return (
        <div>
            <UnauthenticatedRouter/>
        </div>
    )
}

export default Main