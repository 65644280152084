import {useEffect} from "react";
import {useCountriesAction} from "./useCountriesAction";
import {useCountriesData} from "./useCountriesData";

export const useCountries = ({exhibition}) => {
    const {getCountries} = useCountriesAction()
    const {loading, sortedCountries: countries, phoneCodes, defaultCountry, defaultPhoneCode} = useCountriesData({exhibition})
    useEffect(() => {
        getCountries()
    }, [getCountries, exhibition?.preregistrationCountry])
    return {
        defaultCountry,
        defaultPhoneCode,
        phoneCodes,
        countries,
        loading
    }
}