import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../helpers/constants";

const useVisitorIsValid = ({exhibitionId, key}) => {
    const [visitorIsValid, setVisitorIsValid] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(Boolean(key)) {
            setLoading(true)
            axios({
                url: `${API}/en/visitor/check/${key}`,
                method: 'post',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => {
                const {
                    ExhibitionId,
                    StatusId
                } = response.data
                if(ExhibitionId === exhibitionId && [1,2,3,4,8].includes(Number(StatusId))) {
                    setVisitorIsValid(true)
                    setLoading(false)
                }
            }).catch((error) => {
                setVisitorIsValid(false)
                setLoading(false)
            })
        }
    }, [key])
    return {
        loading,
        visitorIsValid
    }
}

export default useVisitorIsValid