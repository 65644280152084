const mode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'development' : 'production'

export const configuration = window.GLOBAL_CONFIGURATION[mode]

export const API = mode === 'production' ? configuration.API_HOST[window.location.hostname] : configuration.API_HOST;
export const PortalToken = window.GLOBAL_CONFIGURATION.portalToken

export const Languages = {
    EL: 'el',
    EN: 'en',
}

export const EXHIBITION_IMAGE_RELATIVE_PATH = '/attachments/exhLogos/'
export const IP_INFO_TOKEN = '9f7750962a0104'

export const PreregistrationRegions = {
    GreeceOnly: '2',
    Everywhere: '0',
    AbroadOnly: '1'
}

export const CatalogueLanguages = {
    ALL: 0,
    EN: 1,
    EL: 2
}