import {useEffect, useState} from "react";
import useExhibitionAction from "./useExhibitionAction";
import useExhibitionData from "./useExhibitionData";
import axios from "axios";
import {API} from "../../helpers/constants";
import {mapExhibition, mapVisitorDataToPreregistrationFormValues} from "../../types/exhibition/map";
import useUserAction from "../user/useUserAction";

export const useExhibition = (id) => {
    const [loading, setLoading] = useState(false)
    const [exhibition, setExhibition] = useState()
    const {setUserLanguage} = useUserAction()
    useEffect(() => {
        axios({
            url: `${API}/en/exhibitionDetailsPublic/${id}`,
            method: 'get',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => {
            const data = response.data.exhibition
            setExhibition(data.length > 0 ? mapExhibition(data[0]) : {})
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        })
    }, [id])
    return {
        loading,
        exhibition
    }
}