// @flow
import React from 'react';
import type {Exhibition} from "../../types/exhibition/types";
import type {Interests} from "../../types/interests/type";
import type {Countries} from "../../types/countries/type";
import Box from "@mui/material/Box";
import {Trans, useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../locales/components/namespaces";
import {eventStatus, formatDate} from "../../helpers/date";
import {Alert, AlertTitle, Paper} from "@mui/material";
import Top from "../top/top";
import type {VisitorCategory} from "../../types/categories/type";
import Form from "../form/form";

type Props = {
    id: string,
    exhibition: Exhibition,
    countries?: Countries,
    interests?: Interests,
    categories?: VisitorCategory,
    phoneCodes?: Array,
    defaultCountry?: Countries,
    defaultPhoneCode?: Object,
    preRegistrationIsValid: boolean,
    visitorIsValid: boolean,
    personalLinkVisitorKey?: string
};

const DisplayForm = ({
                         id,
                         exhibition,
                         countries,
                         interests,
                         categories,
                         phoneCodes,
                         defaultCountry,
                         defaultPhoneCode,
                         preRegistrationIsValid,
                         visitorIsValid,
                         personalLinkVisitorKey
                     }: Props) => {
    const {t} = useTranslation(VISITOR_PROFILE)
    const preregistrationStatus = eventStatus(exhibition.preregistrationFrom, exhibition.preregistrationTo)
    return (
        <Box
            sx={{
                px: 2,
                width: {
                    xs: "100%",
                    md: "50%"
                },
            }}>
            <Paper
                variant={"outlined"}
                sx={{
                    mb: '0',
                    padding: '2px 2px 0',
                    width: '100%',
                    marginBottom: '8px'
                }}>
                <Top exhibition={exhibition}/>
                {!preRegistrationIsValid ? (
                    <Alert sx={{justifyContent: 'center', alignItems: 'center'}} severity="info">
                        <AlertTitle>{t('Info')}</AlertTitle>
                        {t('Preregistration is disabled for this exhibition.')}
                    </Alert>
                ) : Boolean(personalLinkVisitorKey) && !visitorIsValid ? (
                    <Alert sx={{justifyContent: 'center', alignItems: 'center'}} severity="info">
                        <AlertTitle>{t('Info')}</AlertTitle>
                        {t('You have already passed the preregistration process.')}
                    </Alert>
                ) : preregistrationStatus === eventStatus.status.UPCOMING ? (
                    <React.Fragment>
                        <Alert severity="info" sx={{justifyContent: 'center', alignItems: 'center'}}>
                            <AlertTitle>{t('Info')}</AlertTitle>
                            <Trans
                                i18nKey={'preregistration_upcoming_warning'}
                                ns={VISITOR_PROFILE}
                                values={{
                                    date: formatDate(exhibition.preregistrationFrom, formatDate.formatTypes.DATE_ONLY)
                                }}
                                defaults={"Pre-registration will open at <bold>{{date}}</bold>."}
                                components={{bold: <strong/>}}
                            />
                        </Alert>
                    </React.Fragment>
                ) : preregistrationStatus === eventStatus.status.ONGOING ? (
                    <React.Fragment>
                        <Alert severity="info" sx={{justifyContent: 'center', alignItems: 'center'}}>
                            <AlertTitle>{t('Info')}</AlertTitle>
                            {t('Proceed with your preregistration by filling the form below.')}<br/>
                            {exhibition.preregistrationTo && (
                                <Trans
                                    i18nKey={'preregistration_submit_warning'}
                                    ns={VISITOR_PROFILE}
                                    values={{
                                        date: formatDate(exhibition.preregistrationTo, formatDate.formatTypes.DATE_ONLY),
                                    }}
                                    defaults={"This form will remain active up until <bold>{{date}}</bold>."}
                                    components={{bold: <strong/>}}
                                />
                            )}
                        </Alert>
                        <Paper variant={"outlined"} sx={{p: 2, mt: 2, minHeight: window.location === window.parent.location ? '100%' : 'calc(100vh - 250px)', display: 'flex', alignItems: 'flex-end'}} >
                            {exhibition && countries && phoneCodes && defaultCountry && defaultPhoneCode &&
                                <Form
                                    id={id}
                                    exhibition={exhibition}
                                    countries={countries}
                                    interests={interests}
                                    phoneCodes={phoneCodes}
                                    defaultCountry={defaultCountry}
                                    defaultPhoneCode={defaultPhoneCode}
                                    categories={categories}
                                    visitorIsValid={visitorIsValid}
                                    preRegistrationIsValid={preRegistrationIsValid}
                                    personalLinkVisitorKey={personalLinkVisitorKey}
                                />}
                        </Paper>
                    </React.Fragment>
                ) : null}
            </Paper>
        </Box>
    );
};

export default DisplayForm