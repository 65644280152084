// @flow
import React from 'react';
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {SearchOutlined} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import FieldText from "./fieldText";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import useTaxisSearch from "../../../hooks/taxis/useTaxisSearch";
import TaxisDataDialog from "../../taxiDataDialog/taxisDataDialog";

type Props = {};

const TaxisSearch = (props: Props) => {
	const {t} = useTranslation(VISITOR_PROFILE)
	const {field, value, handleBlur, fieldRef, formik, handleChange, errors, touched, disabled, values, setFilledByTaxisSearch, filledByTaxisSearch} = props
	const {loading, taxisSearch, taxisData, handleTaxisData, handleSelections} = useTaxisSearch({formik, setFilledByTaxisSearch, filledByTaxisSearch})
	return (
		<Box
			sx={{
				display: 'flex'
			}}
		>
			<FieldText
				field={field}
				value={value}
				handleBlur={handleBlur}
				formik={formik}
				fieldRef={fieldRef}
				values={values}
				handleChange={handleChange}
				touched={touched}
				errors={errors}
				disabled={disabled}
			/>
			<LoadingButton
				size={"small"}
				startIcon={<SearchOutlined/>}
				variant="text"
				color="success"
				onClick={() => taxisSearch(fieldRef?.current?.value)}
				sx={{minHeight: '40px', minWidth: '120px'}}
				loading={loading}
				loadingPosition="start"
			>
				{normalizeGreek(t('TAXIS Search'))}
			</LoadingButton>
			<TaxisDataDialog
				taxisData={taxisData}
				handleDialog={handleTaxisData}
				handleSelections={handleSelections}
			/>
		</Box>
	);
};

export default TaxisSearch