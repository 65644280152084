import * as ActionTypes from '../../actions/types'

const INITIAL_STATE = {
    loading: false,
    language: 'en',
    errorRegister: null,
    registeredSuccessfully: null,
}

function setUserLanguage(state, action) {
    return state
        .set('language', action.payload);
}

function userInviteRegister(state) {
    return state
        .set('registeredSuccessfully', null)
        .set('errorRegister', null)
        .set('loading', true);
}

function userInviteRegisterSucceeded(state, action) {
    // window.gtag_report_conversion()
    // window.gtag('event', 'click', {
    //     'event_category': 'registration_form',
    //     'action': 'click',
    //     'Account ID': 'UA-137823319-1',
    //     'send_to': 'UA-137823319-1',
    // });
    return state
        .set('registeredSuccessfully', true)
        .set('errorRegister', null)
        .set('loading', false);
}

function userInviteRegisterFailed(state, action) {
    const {message} = action.payload
    return state
        .set('registeredSuccessfully', false)
        .set('errorRegister', message)
        .set('loading', false);
}

function clearRegisteredSuccessfullyFlag(state, action) {
    return state
        .set('registeredSuccessfully', null)
}

const currentUser = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.USER_INVITE_REGISTER]: userInviteRegister,
        [ActionTypes.USER_INVITE_REGISTER_SUCCEEDED]: userInviteRegisterSucceeded,
        [ActionTypes.USER_INVITE_REGISTER_FAILED]: userInviteRegisterFailed,
        [ActionTypes.CLEAR_REGISTERED_SUCCESSFULLY_FLAG]: clearRegisteredSuccessfullyFlag,
        [ActionTypes.SET_USER_LANGUAGE]: setUserLanguage,
    }
}

export default currentUser