// @flow
import React, {useEffect, useState} from 'react';
import type {Exhibition} from "../../types/exhibition/types";
import type {Interests} from "../../types/interests/type";
import type {Countries} from "../../types/countries/type";
import Box from "@mui/material/Box";
import type {VisitorCategory} from "../../types/categories/type";
import {useForm} from "../../hooks/preRegistration/useForm";
import {FormikProvider} from "formik";
import Fields from "../fields/fields";
import FormToolbar from "../formToolbar/formToolbar";
import InfoPopOver from "../infoPopOver/infoPopOver";
import {Alert, AlertTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../locales/components/namespaces";
import AgreeToTermsPopOver from "../agreeToTermsPopOver/agreeToTermsPopOver";

type Props = {
    id: string,
    exhibition: Exhibition,
    countries?: Countries,
    interests?: Interests,
    categories?: VisitorCategory,
    phoneCodes?: Array,
    defaultCountry?: Countries,
    defaultPhoneCode?: Object,
    personalLinkVisitorKey?: string
};

export const PreRegistrationContext = React.createContext(null)

const Form = ({
                  id,
                  exhibition,
                  countries,
                  interests,
                  categories,
                  phoneCodes,
                  defaultCountry,
                  defaultPhoneCode,
                  personalLinkVisitorKey
              }: Props) => {
    const {t} = useTranslation(VISITOR_PROFILE)
    const {
        loading,
        companyNameFieldRef,
        nameFieldRef,
        surnameFieldRef,
        vatFieldRef,
        emailFieldRef,
        mobilePhoneFieldRef,
        phoneFieldRef,
        companyAddressFieldRef,
        cityFieldRef,
        companyWebsiteFieldRef,
        facebookFieldRef,
        instagramFieldRef,
        positionFieldRef,
        personNumberFieldRef,
        postalCodeFieldRef,
        otherInterestsFieldRef,
        formik,
        fields,
        fieldValues,
        submitForm,
        onPhoneCodeChange,
        errorRegister,
        registeredSuccessfully,
        filledByTaxisSearch,
        setFilledByTaxisSearch
    } = useForm({
        id,
        exhibition,
        countries,
        interests,
        categories,
        phoneCodes,
        defaultCountry,
        defaultPhoneCode,
        personalLinkVisitorKey
    })

    const [infoPopOver, setInfoPopOver] = useState({
        anchorEl: null,
        open: false,
        body: ''
    })

    const [agreeToTermsPopOver, setAgreeToTermsPopOver] = useState({
        anchorEl: null,
        open: false,
        body: ''
    })

    const handleInfoPopOver = (value) => {
        setInfoPopOver({...value})
    }

    const handleAgreeToTermsPopOver = (value) => {
        setAgreeToTermsPopOver({...value})
    }

    useEffect(() => {
        if (registeredSuccessfully === true && exhibition.preregistrationRedirect && window.location === window.parent.location) {
            setTimeout(() => {
                window.location.href = exhibition.preregistrationRedirect;
            }, 3000)
        }
    }, [registeredSuccessfully])

    const submitMessage = `Your pre-registration was successful. ${exhibition.sendEmailAfterPreregistration || exhibition.sendSMSAfterPreregistration ? `You will receive ${exhibition.sendEmailAfterPreregistration ? 'an email' : ''} ${exhibition.sendSMSAfterPreregistration ? 'and an SMS' : ''} with the details for your participation.` : ''}`

    return registeredSuccessfully ? (
        <Alert severity="success" sx={{justifyContent: 'center', alignItems: 'center', mb: 2, width: '100%'}}>
            {t(submitMessage)}
        </Alert>
    ) : (
        <Box sx={{width: '100%'}}>
            <PreRegistrationContext.Provider
                value={{
                    id: id,
                    exhibition: exhibition,
                    searchOnlyAmongExhibitionVisitors: false,
                    interests: interests,
                    categories: categories,
                    countries: countries,
                    phoneCodes: phoneCodes,
                    companyNameFieldRef: companyNameFieldRef,
                    nameFieldRef: nameFieldRef,
                    surnameFieldRef: surnameFieldRef,
                    vatFieldRef: vatFieldRef,
                    emailFieldRef: emailFieldRef,
                    mobilePhoneFieldRef: mobilePhoneFieldRef,
                    phoneFieldRef: phoneFieldRef,
                    companyAddressFieldRef: companyAddressFieldRef,
                    cityFieldRef: cityFieldRef,
                    companyWebsiteFieldRef: companyWebsiteFieldRef,
                    instagramFieldRef: instagramFieldRef,
                    facebookFieldRef: facebookFieldRef,
                    positionFieldRef: positionFieldRef,
                    personNumberFieldRef: personNumberFieldRef,
                    postalCodeFieldRef: postalCodeFieldRef,
                    otherInterestsFieldRef: otherInterestsFieldRef,
                    defaultCountry: defaultCountry,
                    defaultPhoneCode: defaultPhoneCode,
                    onPhoneCodeChange: onPhoneCodeChange,
                    handleAgreeToTermsPopOver: handleAgreeToTermsPopOver,
                    filledByTaxisSearch: filledByTaxisSearch,
                    setFilledByTaxisSearch: setFilledByTaxisSearch
                }}
            >
                <FormikProvider value={formik}>
                    <Box
                        id={"preregistration"}
                        component="form"
                        autoComplete={'off'}
                        onSubmit={formik.handleSubmit}
                        noValidate
                    >
                        <Fields
                            formik={formik}
                            fields={fields}
                            values={fieldValues}
                            handleBlur={formik.handleBlur}
                            handleChange={formik.handleChange}
                            touched={formik.touched}
                            errors={formik.errors}
                        />
                    </Box>
                    {errorRegister && (
                        <Alert severity="error" sx={{justifyContent: 'center', alignItems: 'center', mt: 3}}>
                            <AlertTitle>{t('Error')}</AlertTitle>
                            {t(errorRegister)}
                        </Alert>
                    )}
                    <FormToolbar
                        loading={loading}
                        formik={formik}
                        submitForm={submitForm}
                        handleInfoPopOver={handleInfoPopOver}
                    />
                </FormikProvider>
                <InfoPopOver
                    infoPopOver={infoPopOver}
                    handleInfoPopOver={handleInfoPopOver}
                />
                <AgreeToTermsPopOver
                    agreeToTermsPopOver={agreeToTermsPopOver}
                    handleAgreeToTermsPopOver={handleAgreeToTermsPopOver}
                />
            </PreRegistrationContext.Provider>
        </Box>
    );
};

export default Form