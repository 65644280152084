//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultiple} from "../../helpers/parse";
import _ from "lodash";
import {parseVisitorCategory} from "../../types/categories/parse";
import type {VisitorCategory} from "../../types/categories/type";

const selectLoading = createSelector(
    (state) => state.categories.get('loading'),
    (item) => item
)

const selectItems = createSelector(
    [
        (state) => state.categories.get('byId'),
        (state) => state.currentUser.get('language')
    ],
    (data, userLanguage) => parseMultiple(data, (i) => parseVisitorCategory(i, userLanguage))
)

export const useCategoriesData = () => {
    const loading: boolean = useSelector(selectLoading)
    const getCategories: Array<VisitorCategory> = useSelector(selectItems)
    const categories = getCategories.map(category => ({
        id: category.id,
        description: category.text + (category.explanation ? ' (' + category.explanation + ')' : '')
    }))

    return {
        loading,
        categories
    }
}