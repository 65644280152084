import * as ActionTypes from '../actions/types'
import * as Actions from '../actions/actions'
import {requestCreator} from '../helpers/requestCreator'

const getExhibitionDetails = requestCreator({
    url: '/exhibitionDetailsPublic/:id',
    hasPathParameters: ['id'],
    method: 'GET',
    onSuccess: Actions.getExhibitionDetailsSucceeded,
    onFailure: Actions.getExhibitionDetailsFailed,
})

const getCountries = requestCreator({
    url: '/country/list',
    method: 'GET',
    onSuccess: Actions.getCountriesSucceeded,
    onFailure: Actions.getCountriesFailed,
})

const getInterests = requestCreator({
    url: '/exhibition/interests/:id',
    hasPathParameters: ['id'],
    method: 'GET',
    onSuccess: Actions.getInterestsSucceeded,
    onFailure: Actions.getInterestsFailed,
})

const getVisitorCategories = requestCreator({
    url: '/exhibition/characterizations/:id',
    hasPathParameters: ['id'],
    method: 'GET',
    onSuccess: Actions.getVisitorCategoriesSucceeded,
    onFailure: Actions.getVisitorCategoriesFailed,
})

const userInviteRegister = requestCreator({
    url: '/visitor/storepublic',
    method: 'POST',
    onSuccess: Actions.userInviteRegisterSucceeded,
    onFailure: Actions.userInviteRegisterFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const requestHandler = {
    [ActionTypes.GET_EXHIBITION_DETAILS]: getExhibitionDetails,
    [ActionTypes.GET_COUNTRIES]: getCountries,
    [ActionTypes.GET_INTERESTS]: getInterests,
    [ActionTypes.GET_VISITOR_CATEGORIES]: getVisitorCategories,
    [ActionTypes.USER_INVITE_REGISTER]: userInviteRegister
}

export default requestHandler
