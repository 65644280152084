// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../locales/components/namespaces";
import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, Divider,
	FormControl,
	FormControlLabel, Grid
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DialogField from "./dialogField";
import {normalizeGreek} from "../../helpers/normalizeGreek";

type Props = {

};

const TaxisDataDialog = (props: Props) => {
	const {taxisData, handleDialog, handleSelections} = props
	const {t} = useTranslation(VISITOR_PROFILE)
	const handleClose = (evt, details) => {
		if (details === 'backdropClick' || details === 'escapeKeyDown') return
		handleDialog({
			open: false,
			data: null
		})
	}
	return (
		<Dialog open={Boolean(taxisData?.open)} onClose={handleClose} maxWidth={'sm'}>
			<DialogTitle>
				<Typography
					variant={'h5'}
					component={'div'}
					sx={{textAlign: 'center'}}
				>
					{t('Select Company Data From Taxis')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogField
					taxisData={taxisData}
					handleDialog={handleDialog}
					field={'companyName'}
					label={t('Company Name')}
				/>
				<Divider/>
				<DialogField
					taxisData={taxisData}
					handleDialog={handleDialog}
					field={'companyAddress'}
					label={t('Company Address')}
				/>
				<Divider/>
				<DialogField
					taxisData={taxisData}
					handleDialog={handleDialog}
					field={'city'}
					label={t('City')}
				/>
				<Divider/>
				<DialogField
					taxisData={taxisData}
					handleDialog={handleDialog}
					field={'postalCode'}
					label={t('Postal Code')}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					color="error"
					size={"small"}
					onClick={handleClose}
					sx={{
						marginRight: 'auto'
					}}
				>
					{normalizeGreek(t('Close'))}
				</Button>
				<Button
					color="success"
					size={"small"}
					onClick={handleSelections}
				>
					{normalizeGreek(t('Fill Selections'))}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default TaxisDataDialog