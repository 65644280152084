import React, {useContext, useMemo} from "react";
import Checkbox from "./partials/checkbox";
import Text from "./partials/text";
import InterestsCheckBoxList from "./partials/interestsCheckBoxList";
import SelectCountry from "./partials/selectCountry";
import SelectCategory from "./partials/selectCategory";
import MobilePhone from "./partials/mobilePhone";
import {PreRegistrationContext} from "../form/form";

const getInputComponent = (fieldType) => {
    switch (fieldType) {
        case ("agreeToTerms"):
            return Checkbox
        case ("isIndividual"):
            return Checkbox
        case ("hasRevisited"):
            return Checkbox
        case ("country"):
            return SelectCountry
        case ("characterization"):
            return SelectCategory
        case ("interests"):
            return InterestsCheckBoxList
        case ("mobile"):
            return MobilePhone
        default:
            return Text
    }
}

const Input = React.memo((props) => {
    const {formik, values, field, value, handleBlur, handleChange, touched, errors, disabled} = props
    const InputComponent = getInputComponent(field.type)
    const {filledByTaxisSearch, setFilledByTaxisSearch} = useContext(PreRegistrationContext)
    const fieldDisabled = useMemo(() => (
        field.id === 'companyName' ? filledByTaxisSearch.companyName :
            field.id === 'companyAddress' ? filledByTaxisSearch.companyAddress :
                field.id === 'city' ? filledByTaxisSearch.city :
                    field.id === 'postalCode' ? filledByTaxisSearch.postalCode :
                        field.id === 'vatNumber' ? filledByTaxisSearch.vatNumber :
                            disabled
    ), [field.id, filledByTaxisSearch.companyName, filledByTaxisSearch.companyAddress, filledByTaxisSearch.city, filledByTaxisSearch.postalCode, filledByTaxisSearch.vatNumber, disabled])
    return (
        <div>
            <InputComponent
                disabled={fieldDisabled}
                formik={formik}
                values={values}
                field={field}
                value={value}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                filledByTaxisSearch={filledByTaxisSearch}
                setFilledByTaxisSearch={setFilledByTaxisSearch}
            />
        </div>
    )
})

export default Input
